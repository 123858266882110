$(window).on('load', function() {
  //---------------------------------
  // 共通headerの高さを取得
  //---------------------------------
  function getHeaderHeight() {
    const $header = $('#header');
    const $announceHeader = $('.top-newslist-announce');
    const $lHeader = $('.l-header');
    const $lHeaderMain = $('.l-header__main');
    const $gNav = $('.l-header__global.u-pc--hide');
    const headerHeight = $header.height();
    const announceHeaderHeight = $announceHeader.height();
    const lHeaderHeight = $lHeader.height();
    const lHeaderMainHeight = $lHeaderMain.height();
    const gNavHeight = $gNav.height();

    return {
      header: $header,
      announceHeader: $announceHeader,
      lHeader: $lHeader,
      lHeaderMain: $lHeaderMain,
      gNav: $gNav,
      headerHeight: headerHeight,
      announceHeaderHeight: announceHeaderHeight,
      lHeaderHeight: lHeaderHeight,
      lHeaderMainHeight: lHeaderMainHeight,
      gNavHeight: gNavHeight,
    };
  }

  //---------------------------------
  // hamburger menu
  //---------------------------------
  function hamburger() {
    const $html = $('html');
    const $burgerBtn = $('#js-hamburger-btn');

    $burgerBtn.on('touch click', function () {
      $html.toggleClass('is-open--drawer');

      if ($(this).attr('aria-expanded') === 'false') {
        $(this).attr('aria-expanded', true);
      } else {
        $(this).attr('aria-expanded', false);
      }
    });
  }

  //---------------------------------
  // hamburger menu position
  //---------------------------------
  function hamburgerPosition() {
    const sp = 768;
    const $burgerContent = $('.l-drawer.u-pc--hide');
    const obj = getHeaderHeight();
    const $announceHeader = obj.announceHeader;
    const announceHeaderHeight = obj.announceHeaderHeight;
    const lHeaderMainHeight = obj.lHeaderMainHeight;

    if (window.innerWidth <= sp) {
      $(window).scroll(function () {
        if ($announceHeader.length) {
          const scrollPosition = $(this).scrollTop();

          if (scrollPosition < announceHeaderHeight) {
            $burgerContent.css({
              'margin-top':
                lHeaderMainHeight +
                announceHeaderHeight -
                scrollPosition +
                'px',
            });
          } else {
            $burgerContent.css({
              'margin-top': `${lHeaderMainHeight}px`,
            });
          }
        } else {
          $burgerContent.css({
            'margin-top': `${lHeaderMainHeight}px`,
          });
        }
      });
    }
  }

  //---------------------------------
  // Smooth Scrolling (anchor link + go to top)
  //---------------------------------
  function fadeBtnAnime() {
    const $elem = $('#js-scroll-top');

    $(window).scroll(function () {
      if ($(this).scrollTop() > 200) {
        $elem.fadeIn(200, function () {
          $elem.css('display', 'inline-block');
          $elem.css('opacity', 1);
          $elem.children().css('opacity', 1);
        });
      } else {
        $elem.fadeOut(200, function () {
          $elem.css('opacity', 0);
          $elem.children().css('opacity', 0);
        });
      }
    });
  }

  function stopElement() {
    const $elem = $('#js-scroll-top');

    $(window).scroll(function () {
      let documentHeight = $(document).height();
      let currentPosition = $(window).height() + $(window).scrollTop();
      let footerHeight = $('footer').innerHeight();

      if (documentHeight - currentPosition <= footerHeight) {
        $elem.css({
          position: 'absolute',
          bottom: footerHeight + 25,
        });
      } else {
        $elem.css({
          position: 'fixed',
          bottom: '25px',
        });
      }
    });
  }

  function smoothScrolling(stopElement, fadeBtnAnime) {
    const $anchor = $('a[href^="#"]');

    fadeBtnAnime();
    stopElement();
    const obj = getHeaderHeight();
    const lHeaderMainHeight = obj.lHeaderMainHeight;

    if ($anchor.length) {
      $anchor.on('touch click', function (e) {
        e.preventDefault();

        let href = $(this).attr('href');
        let target = href == '#' || href == '' ? 'html' : href;
        let gap = lHeaderMainHeight + 20; // ヘッダーの高さ + shadow分
        let position = $(target).offset().top - gap;

        $('html, body').stop().animate(
          {
            scrollTop: position,
          },
          'slow'
        );
        return false;
      });
    }
  }

  //---------------------------------
  // TOP 重要なお知らせ アコーディオン
  //---------------------------------

  function addAnnounceAccordion() {
    let $news = $('.top-newslist-announce .news-entries');
    let $entry = $news.children('.news-entry');
    if (1 < $entry.length) {
      $news.after(
        '<div class="announce-accordion"><span>ひらく</span><img src="/f/resources/images/common/icon_message.svg"></div>'
      );
    }
  }

  function toggleAnnounceList() {
    let $news = $('.top-newslist-announce');
    let $btn = $news.find('.announce-accordion');
    $btn.on('click', function () {
      if ($news.hasClass('is-open')) {
        $(this).children('span').text('ひらく');
        $news.removeClass('is-open');
      } else {
        $(this).children('span').text('とじる');
        $news.addClass('is-open');
      }

      getHeaderHeight();
      hamburgerPosition();
      scrollToHeaderFixed();
      scrollToGlobalNavPosition();
    });
  }

  //---------------------------------
  // 共通header スクロール固定
  //---------------------------------
  function scrollToHeaderFixed() {
    const obj = getHeaderHeight();
    const $header = obj.header;
    const $announceHeader = obj.announceHeader;
    const $lHeader = obj.lHeader;
    const announceHeaderHeight = obj.announceHeaderHeight;
    const lHeaderHeight = obj.lHeaderHeight;

    const $nextElem = $header.next().is('nav#nav')
      ? $('nav#nav')
      : $('div#outer');

    // $(window).scroll(function () {
    //   if ($announceHeader.length) {
    //     const scrollTop = $(this).scrollTop();

    //     if (scrollTop > announceHeaderHeight) {
    //       $lHeader.addClass('is-fixed');
    //       $nextElem.css('margin-top', lHeaderHeight);
    //     } else {
    //       $lHeader.removeClass('is-fixed');
    //       $nextElem.css('margin-top', 0);
    //     }
    //   } else {
    //     $lHeader.addClass('is-fixed');
    //     $nextElem.css('margin-top', lHeaderHeight);
    //   }
    // });
    $(window).scroll(function () {
      if ($announceHeader.length) {
        const scrollTop = $(this).scrollTop();

        if (scrollTop > announceHeaderHeight) {
          $lHeader.addClass('is-fixed');
          if ($('body').hasClass('select') || $('.select-top').length) {
            $nextElem.css('margin-top', 0);
            $('.l-heading.select').css('margin-top', lHeaderHeight);
          } else {
            $nextElem.css('margin-top', lHeaderHeight);
          }
        } else {
          $lHeader.removeClass('is-fixed');
          $nextElem.css('margin-top', 0);
          if ($('body').hasClass('select') || $('.select-top').length) {
            $('.l-heading.select').css('margin-top', 0);
          }
        }
      } else {
        $lHeader.addClass('is-fixed');
        if ($('body').hasClass('select') || $('.select-top').length) {
          $nextElem.css('margin-top', 0);
          $('.l-heading.select').css('margin-top', lHeaderHeight);
        } else {
          $nextElem.css('margin-top', lHeaderHeight);
        }
      }
    });

    $(window).trigger('scroll');
  }

  //---------------------------------
  // SP時 共通headerのグロナビの表示非表示設定
  //---------------------------------
  function scrollToGlobalNavPosition() {
    const sp = 768;

    const obj = getHeaderHeight();
    const headerHeight = obj.headerHeight;
    const $gNav = obj.gNav;
    const gNavHeight = obj.gNavHeight;

    if (window.innerWidth <= sp) {
      let startPosition = 0;
      let scrollPosition;

      $(window).scroll(function () {
        scrollPosition = $(this).scrollTop();

        if (scrollPosition <= startPosition || scrollPosition < 100) {
          $gNav.css({
            'margin-top': '0',
          });
        } else if (scrollPosition > headerHeight - gNavHeight) {
          $gNav.css({
            'margin-top': `-${gNavHeight}px`,
            transition: 'margin 0.3s',
            'z-index': '-1',
          });
        }

        // 現在位置の更新
        startPosition = scrollPosition;
      });
    }
  }

  //---------------------------------
  // 初期化
  //---------------------------------
  function init() {
    hamburger();
    smoothScrolling(stopElement, fadeBtnAnime);
    addAnnounceAccordion();
    toggleAnnounceList();
    hamburgerPosition();
    scrollToHeaderFixed();
    scrollToGlobalNavPosition();
    slick.init();
  }
  init();
  
  //---------------------------------
  // サインアップ共通　発売日お知らせメール | アコーディオン
  //---------------------------------
  const classIdName = '.a9816990-05da-4433-9d0d-2ef9965732b0';
  const inputLabel = document.querySelector(`${classIdName} .input-label`);
  const classElement = document.querySelector(classIdName);

  if (inputLabel && classElement) {
    document
      .querySelector(`${classIdName} .input-label`)
      .addEventListener('click', function () {
        document.querySelector(classIdName).classList.toggle('is-open');
      });
  }
});
